<template>
  <div>
    <br />
    <div>
      <h1>Data Management</h1>
    </div>
    <v-tabs>
      <v-tab>Missing Site Metrics</v-tab>
      <v-tab>Missing Update Metrics</v-tab>
      <v-tab>Missing Estimates</v-tab>
      <v-tab>Report Groups</v-tab>
      <v-tab>Billing Plans</v-tab>
      <v-tab>Cache</v-tab>
      <v-tab>GMP</v-tab>
      <v-tab>Solcast</v-tab>
      <v-tab-item>
        <v-form ref="form">
          <br />
          <div>
            <h5 class="ml-1">
              Select Monitoring System and date range for data reload
            </h5>
            <div class="small ml-2">
              These metrics will show how many dates are missing (no data
              collected) for each site and the given date range. This view will
              allow you to select the given data provider and a date range. Once
              you click the <b>Show Missing</b> button, you will see all the
              dates that are missing for the given site. There is also a refresh
              button that will attempt to collect the kwh data for the selected
              site and the given date range.
            </div>
          </div>
          <v-select
            :items="items"
            label="Select System"
            class="ml-2"
            v-model="monitorSystem"
            required
          ></v-select>
          <v-row>
            <!-- <v-col cols="12" >
            Select a begin and end date
        </v-col> -->
            <v-col cols="6" md="6">
              <v-menu
                v-model="fromDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="From"
                    prepend-icon="event"
                    readonly
                    :value="fromDateDisp"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="en-in"
                  v-model="fromDateVal"
                  no-title
                  @input="fromDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="6" md="6">
              <v-menu
                v-model="toDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    label="To"
                    prepend-icon="event"
                    readonly
                    :value="toDateDisp"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="en-in"
                  v-model="toDateVal"
                  no-title
                  @input="toDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-btn
            :ripple="false"
            :elevation="0"
            class="font-weight-bold text-xs btn-default bg-gradient-default"
            @click="showMissing()"
            id="metrics_missing"
          >
            Show Missing</v-btn
          >
          <v-btn
            :ripple="false"
            :elevation="0"
            class="font-weight-bold text-xs btn-default bg-gradient-default"
            @click="reloadAll()"
            id="metrics_reload"
          >
            Reload All</v-btn
          >
          <br />
          <v-row>
            <v-col cols="12" md="12">
              <v-data-table
                :headers="headers"
                :items="sites"
                :items-per-page="5"
                class="elevation-1"
              >
                <template v-slot:item.controls="props">
                  <v-btn
                    class="mx-2"
                    icon
                    color="green"
                    @click="refreshItem(props.item)"
                  >
                    <v-icon>mdi-cached</v-icon>
                  </v-btn>
                </template>
                <template v-slot:item.missing_site_tof="{ item }">
                  {{ item.missing_site_tof ? "true" : "false" }}
                </template>
                <template v-slot:item.missing_inverter_tof="{ item }">
                  {{ item.missing_inverter_tof ? "true" : "false" }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>

          <!-- <v-btn color="success" class="mr-4" @click="dataReload()"> Submit </v-btn> -->
        </v-form>
      </v-tab-item>
      <v-tab-item>
        <failed-update></failed-update>
      </v-tab-item>
      <v-tab-item>
        <missing-estimate></missing-estimate>
      </v-tab-item>
      <v-tab-item>
        <reporting-group> </reporting-group>
      </v-tab-item>
      <v-tab-item>
        <billing-plan></billing-plan>
      </v-tab-item>
      <v-tab-item>
        <!-- reset cache button -->
        <v-btn
            :ripple="false"
            :elevation="0"
            class="font-weight-bold text-xs btn-default bg-gradient-default"
            @click="resetCache()"
          >
            Reset Cache</v-btn
          >
      </v-tab-item>
      <v-tab-item>
        <gmp-custom-report></gmp-custom-report>
      </v-tab-item>
      <v-tab-item>
        <solcast-admin></solcast-admin>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import Service from "@/services/Service.js";
import Vue from "vue";

import VueToast from "vue-toast-notification";
// Import one of the available themes
import "vue-toast-notification/dist/theme-default.css";
import ReportingGroup from "./admin/ReportingGroup.vue";
import BillingPlan from "./admin/BillingPlan.vue";
import MissingEstimate from "./admin/MissingEstimate.vue";
import FailedUpdate from "./admin/FailedUpdate.vue";
import GmpCustomReport from './gmp-custom-report.vue';
import SolcastAdmin from './SolcastAdmin.vue';
// import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueToast);

export default {
  components: {
    ReportingGroup,
    BillingPlan,
    MissingEstimate,
    FailedUpdate,
    GmpCustomReport,
    SolcastAdmin
  },
  data() {
    return {
      monitorSystem: null,
      props: null,
      toDateMenu: null,
      fromDateMenu: null,
      fromDateVal: null,
      toDateVal: null,
      items: ["Solaredge", "Fronius", "Solarlog", "Locus", "Also", "Enphase", "Powerdash", "GMP", "Powerfactors", "Allearth", "Auroravision", "Chint", "Egauge", "Pika", "Smasunnyportal", "Solectria"],
      headers: [
        { text: "Site ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "Missing Site Dates", value: "missing_site_stat_dates" },
        {
          text: "Missing Inverter Dates",
          value: "missing_inverter_stat_dates",
        },
        { text: "Missing Site TOF", value: "missing_site_tof" },
        { text: "Missing Inverter TOF", value: "missing_inverter_tof" },
        { text: "Refresh", value: "controls", sortable: false },
      ],
      sites: [],
    };
  },
  mounted() {
    document.title = "Admin"
  },
  beforeMount() {
    var date = new Date();
    this.toDateVal = this.$moment(date).format("YYYY-MM-DD");
    // this.toDateVal = today;
  },
  methods: {
    compareTime(time1, time2) {
      return new Date(time1) >= new Date(time2); // true if time1 is later
    },
    dataReload() {
      var startDate = this.fromDateVal;
      var endDate = this.toDateVal;
      var system = this.monitorSystem.toLowerCase();
      const params = {
        startDate,
        endDate,
      };
      // check to make sure to is greater than or equal to from
      if (!this.compareTime(endDate, startDate)) {
        Vue.$toast.error(`Invalid date selection`, { position: "top-right" });
        return;
      }
      if (system == "locus") {
        Vue.$toast.warning(
          `Data reload for ${system} will only run for current date`,
          { position: "top-right", duration: 4000 }
        );
      }
      console.log(
        `From Date: ${startDate}, To Date: ${endDate}, System: ${system}`
      );
      Service.getSiteDataForProvider(system, params)
        .then((response) => {
          Vue.$toast.success(`Data reload queued for ${system}`, {
            position: "top-right",
            duration: 4000,
          });
        })
        .catch((error) => {
          Vue.$toast.error(`Error reloading data`, { position: "top-right" });
          console.error(error);
        });
    },
    showMissing() {
      var startDate = this.fromDateVal;
      var endDate = this.toDateVal;
      var system = this.monitorSystem.toLowerCase();
      const params = {
        startDate,
        endDate,
      };
      // check to make sure to is greater than or equal to from
      if (!this.compareTime(endDate, startDate)) {
        Vue.$toast.error(`Invalid date selection`, { position: "top-right" });
        return;
      }
      console.log(
        `From Date: ${startDate}, To Date: ${endDate}, System: ${system}`
      );
      NProgress.start();
      Service.getMissingStatsForProvider(system, params)
        .then((response) => {
          //   this.sites = response.data.sites.map(site => {
          //     site.missing_site_tof = String(site.missing_site_tof)
          //     site
          //   })
          this.sites = response.data.sites;
          NProgress.done();
          // Vue.$toast.success(`Data reload queued for ${system}`, {position: "top-right", duration: 4000})
        })
        .catch((error) => {
          NProgress.done();
          Vue.$toast.error(`Error reloading data`, { position: "top-right" });
          console.error(error);
        });
    },
    reloadAll() {
      var startDate = this.fromDateVal;
      var endDate = this.toDateVal;
      var system = this.monitorSystem.toLowerCase();
      const params = {
        startDate,
        endDate,
      };
      // check to make sure to is greater than or equal to from
      if (!this.compareTime(endDate, startDate)) {
        Vue.$toast.error(`Invalid date selection`, { position: "top-right" });
        return;
      }

      console.log(
        `From Date: ${startDate}, To Date: ${endDate}, System: ${system}`
      );
      NProgress.start();
      Service.getSiteDataForProvider(system, params)
        .then((response) => {
          console.log(response.data);
          // this.sites = response.data.sites;
          NProgress.done();
          Vue.$toast.success(`Data reload queued for ${system}`, {
            position: "top-right",
            duration: 4000,
          });
        })
        .catch((error) => {
          NProgress.done();
          Vue.$toast.error(`Error reloading data`, { position: "top-right" });
          console.error(error);
        });
    },
    resetCache() {
      
      Service.resetCache()
        .then((response) => {
          Vue.$toast.success(`Cache Reset`, {
            position: "top-right",
            duration: 4000,
          });
        })
        .catch((error) => {
          Vue.$toast.error(`Error resetting cache`, { position: "top-right" });
          console.error(error);
        });
    },
    refreshItem(item) {
      var startDate = this.fromDateVal;
      var endDate = this.toDateVal;
      var system = this.monitorSystem.toLowerCase();
      const params = {
        startDate,
        endDate,
      };
      // check to make sure to is greater than or equal to from
      if (!this.compareTime(endDate, startDate)) {
        Vue.$toast.error(`Invalid date selection`, { position: "top-right" });
        return;
      }

      console.log(
        `From Date: ${startDate}, To Date: ${endDate}, System: ${system}`
      );
      console.log(`site id: ${item.id}`);
      Service.getSiteData(item.id, params)
        .then((response) => {
          Vue.$toast.success(`Data reload queued for site ${item.name}`, {
            position: "top-right",
            duration: 4000,
          });
        })
        .catch((error) => {
          Vue.$toast.error(`Error reloading data`, { position: "top-right" });
          console.error(error);
        });
    },
  },
  computed: {
    fromDateDisp() {
      return this.fromDateVal;
      // format date, apply validations, etc. Example below.
      // return this.fromDateVal ? this.formatDate(this.fromDateVal) : "";
    },
    toDateDisp() {
      return this.toDateVal;
    },
  },
};
</script>

<style>
#metrics_missing {
  margin-left: 0.5%;
}
#metrics_reload {
  margin-left: 1%;
}
</style>