<template>
  <div>
    <h3>Backfill Site Estimates</h3>
    <form class="">
    <v-text-field
      v-model="siteId"
      outlined
      class="ml-2 mr-2 text-field-width"
      id="site_id"
      placeholder="Enter Site ID"
    ></v-text-field>

    <v-btn
      :ripple="false"
      :elevation="0"
      class="font-weight-bold text-xs btn-default bg-gradient-default ml-2 mb-2"
      :disabled="loading"
      @click="startBackfill"
    >
      {{ loading ? "Processing..." : "Start Backfill" }}
    </v-btn>
    </form>
  </div>
</template>

<script>
import Service from "@/services/Service.js";
import Vue from "vue";
import VueToast from "vue-toast-notification";
// Import one of the available themes
import "vue-toast-notification/dist/theme-default.css";
// import 'vue-toast-notification/dist/theme-sugar.css';

Vue.use(VueToast);

export default {
  data() {
    return {
      siteId: "",
      loading: false,
    };
  },
  methods: {
    startBackfill() {
      if (!this.siteId) {
        Vue.$toast.error("Please enter a Site ID.", { position: "top-right" });
        return;
      }

      this.loading = true;

      Service.backfillEstimates(this.siteId)
        .then((response) => {
          if (response.status == 200) {
            Vue.$toast.success(
              `Data reload queued for site ${this.siteId}`,
              { position: "top-right", duration: 4000 }
            );
          } else {
            Vue.$toast.error("Error reloading data", { position: "top-right" });
          }
        })
        .catch((error) => {
          Vue.$toast.error("Error reloading data", { position: "top-right" });
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.ml-2 {
  margin-left: 8px;
}
.mr-2 {
  margin-right: 8px;
}
.mb-2 {
  margin-bottom: 8px;
}
.text-field-width {
  max-width: 300px;
}
</style>
